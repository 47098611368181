import React, { useEffect, useState } from 'react';
import './styles.css'; // Import the CSS file

const Dashboard = () => {
  const [csvData, setCsvData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [metricsType, setMetricsType] = useState('salesman');
  const [metrics, setMetrics] = useState([]);

  useEffect(() => {
    fetch('https://oms.eyantra.com/api/get-master-csv')
      .then(response => response.json())
      .then(data => {
        setCsvData(data.data);
        setFilteredData(data.data); // Initially display all data
        calculateMetrics(data.data, metricsType); // Calculate initial metrics
      })
      .catch(error => console.error('Error fetching CSV data:', error));
  }, []); // Include metricsType as a dependency

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleMetricsTypeChange = (e) => {
    setMetricsType(e.target.value);
    calculateMetrics(filteredData, e.target.value); // Recalculate metrics based on the new type
  };

  const calculateMetrics = (data, type) => {
    if (data.length > 0) {
      const headers = data[0];
      const metricsIndex = headers.indexOf(type === 'salesman' ? 'Sales POC' : 'Details');
      if (metricsIndex !== -1) {
        const metricsMap = data.slice(1).reduce((acc, row) => {
          const key = row[metricsIndex];
          if (acc[key]) {
            acc[key] += 1;
          } else {
            acc[key] = 1;
          }
          return acc;
        }, {});
        setMetrics(Object.entries(metricsMap));
      }
    }
  };

  useEffect(() => {
    if (searchTerm) {
      const salesOrderNoIndex = csvData[0].indexOf('Sales Order No'); // Find the index of the Sales Order No column
      if (salesOrderNoIndex !== -1) {
        const filtered = csvData.filter((row, index) => {
          // Include headers and rows where the salesOrderNo matches the search term
          return index === 0 || row[salesOrderNoIndex].toLowerCase().includes(searchTerm.toLowerCase());
        });
        setFilteredData(filtered);
        calculateMetrics(filtered, metricsType); // Recalculate metrics on filtered data
      }
    } else {
      setFilteredData(csvData); // Reset to all data when search is cleared
      calculateMetrics(csvData, metricsType); // Recalculate metrics on all data
    }
  }, [searchTerm, csvData, metricsType]);

  return (
    <div className="dashboard-container">
      <h2>Dashboard</h2>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by Sales Order No"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <div className="metrics-container">
        <label>
          Show Metrics By:
        </label>
        <select value={metricsType} onChange={handleMetricsTypeChange}>
          <option value="salesman">Salesman</option>
          <option value="product">Product</option>
        </select>
      </div>
      <div className="metrics">
        {metrics.map(([key, value], index) => (
          <div key={index} className="metric">
            <span className="metric-key">{key}</span>: <span className="metric-value">{value}</span>
          </div>
        ))}
      </div>
      {filteredData.length > 0 ? (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                {filteredData[0].map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredData.slice(1).map((row, rowIndex) => (
                <tr key={rowIndex} className="highlight-on-hover">
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex}>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>Loading data...</p>
      )}
    </div>
  );
};

export default Dashboard;
